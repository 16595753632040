import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/1MT45gCNMqw">
    <SEO title="Who's Slave Are You? - Romans: Principles" />
  </Layout>
)

export default SermonPost
